<template>
  <main class="search-page page">
    <BreadcrumbsComponent title="Поиск" />
    <div class="page__container">
      <div class="search-page__content page__content">
        <div class="search-page__input">
          <label
            :class="{
              'input--error': errors.length,
            }"
            class="search-page__input-label"
          >
            <IconComponent category="default" name="search" />
            <input
              v-model="search"
              class="search-page__input-field"
              placeholder="Поиск по сайту"
              type="text"
              @input="handleInput()"
              autofocus
            />
            <span v-if="loading" class="search-page__input-loading">
              <IconComponent category="default" name="loading" />
            </span>
            <button v-if="!!search.length" @click="clearInput" class="search-page__input-close" type="button">
              <IconComponent category="default" name="close" />
            </button>
          </label>
          <div v-if="errors && errors.length" class="search-page__input-errors">
            <pre v-for="(e, i) in errors" :key="i">{{ e }}</pre>
          </div>
        </div>
        <div v-if="results && results.length" class="search-page__results">
          <div v-for="(result, i) in results" :key="i">
            <router-link :to="route(result.id, result.status.table_name)" class="search-page__result">
              <div class="search-page__result-header">
                <span
                  v-if="result.updated_at && result.status.table_name !== 'workers'"
                  class="search-page__result-date"
                >
                  {{ result.updated_at | humanDateShort }}
                </span>
                <div class="search-page__result-category">
                  {{ category(result.status.table_name) }}
                </div>
              </div>
              <span v-if="result.title" class="search-page__result-title">{{ result.title }}</span>
            </router-link>
          </div>
        </div>
        <span
          v-if="!errors.length && search.length && !loading && !results.length"
          class="search-page__not-found"
        >
          По данному запросу ничего не найдено
        </span>
        <span v-if="!errors.length && !search.length" class="search-page__not-found">
          Введите ваш запрос в поисковую строку
        </span>
        <PaginationComponent
          v-if="!errors.length"
          :first="first"
          :page="page"
          :total="Math.ceil(total / first)"
          :totalItems="parseInt(total || '0')"
          @paginate="paginate"
        />
      </div>
    </div>
  </main>
</template>

<script>
import BreadcrumbsComponent from "components/navigation/BreadcrumbsComponent.vue";
import DEPARTMENT from "gql/queries/department.graphql";
import GLOBAL_SEARCH from "gql/queries/global_search.graphql";
import IconComponent from "components/IconComponent.vue";
import PaginationComponent from "components/PaginationComponent.vue";

export default {
  name: "SearchPage",
  async asyncData({ apollo, store }) {
    await apollo.defaultClient
      .query({
        query: DEPARTMENT,
      })
      .then(({ data }) => {
        store.dispatch("save", data);
      })
      .catch(() => {});
  },
  data() {
    return {
      loading: false,
      errors: [],
      search: "",
      first: 10,
      page: 1,
      total: 0,
      results: [],
    };
  },
  methods: {
    route(id, table_name) {
      let name;
      switch (table_name) {
        case "news": {
          name = "press-item";
          break;
        }
        case "activities": {
          name = "activity-item";
          break;
        }
        case "workers": {
          name = "ministry-management-open";
          break;
        }
        case "acts": {
          name = "document";
          break;
        }
      }
      return { name: name, params: { id: id } };
    },
    category(table_name) {
      switch (table_name) {
        case "news": {
          return "Новость";
        }
        case "activities": {
          return "Деятельность";
        }
        case "workers": {
          return "Должностное лицо";
        }
        case "acts": {
          return "Документ";
        }
      }
      return "";
    },
    clearInput() {
      this.search = "";
      this.results = [];
      this.errors = [];
    },
    paginate(e) {
      this.page = e;
      this.fetch();
    },
    fetch() {
      this.$apollo
        .query({
          query: GLOBAL_SEARCH,
          variables: {
            first: this.first,
            page: this.page,
            title: this.search,
          },
          fetchPolicy: "no-cache",
        })
        .then(({ data }) => {
          if (data && data.global_search) {
            this.results = data.global_search.data;
            this.total = data.global_search.paginatorInfo.total;
          }
        })
        .catch(() => {});
    },
    handleInput() {
      if (this.inputDebounceTimeout) {
        clearTimeout(this.inputDebounceTimeout);
      }
      this.inputDebounceTimeout = setTimeout(() => {
        this.loading = true;
        if (this.search.length >= 3) {
          this.errors = [];
          this.$apollo
            .query({
              query: GLOBAL_SEARCH,
              variables: {
                first: this.first,
                title: this.search,
              },
            })
            .then(({ data }) => {
              if (data && data.global_search) {
                this.results = data.global_search.data;
                this.total = data.global_search.paginatorInfo.total;
              }
              this.loading = false;
            });
        } else {
          this.results = [];
          this.errors = ["Введите больше 3 символов"];
          this.loading = false;
        }
      }, 500);
    },
  },
  metaInfo: {
    title: "Поиск",
  },
  components: {
    PaginationComponent,
    IconComponent,
    BreadcrumbsComponent,
  },
};
</script>

<style lang="stylus">
.search-page {
  grid-gap 30px

  &__content {
    grid-gap 30px
  }

  &__input {
    display grid
    grid-gap 10px
  }

  &__input-label {
    display flex
    align-items center
    padding 20px 30px
    gap 20px
    border 1px solid #DAE5EF
    background var(--white)
    border-radius 10px

    .icon {
      display: flex;
      width: 30px;
      height: 30px;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__input-field {
    border none
    box-sizing border-box
    background none
    width 100%
    font-family var(--font_regular)
    font-size: 1.125em;
    line-height: 19px;
    color var(--black)
    appearance none
    z-index 1
    outline none
    height: 50px;
    margin: -10px 0;

    &::placeholder {
      color var(--black)
    }
  }

  &__input-loading {
    display flex
    flex-shrink 0

    .icon {
      display: flex;
      width: 30px;
      height: 30px;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__input-close {
    background none
    border none
    cursor pointer
    display: flex;
    flex-shrink 0
    align-items center
    justify-content center
  }

  &__input-errors {
    color var(--red)
    font-weight 500
    //font-size 0.875em
    line-height 14px
    display grid
    grid-gap 5px
  }

  &__not-found {
    font-size 1.125em
  }

  &__results {
    display grid
    grid-gap 10px
  }

  &__result {
    display grid
    grid-gap 10px
    padding 19px 20px
    background var(--white)
  }

  &__result-header {
    display flex
    align-items center
    gap 24px
    font-size: 0.75em;
    line-height: 14px;
    color: var(--dark-light);

    & > *:not(:last-child) {
      position relative

      &:after {
        content "•"
        font-weight 700
        absolute right -14px top bottom
        margin auto
        font-size: 1em;
        line-height: 14px;
        color: var(--dark-light);
      }
    }
  }

  &__result-title {
    font-weight 700
    line-height 26px
    color var(--dark)
  }

  &__result-date {
    font-weight: 500;
  }

  &__result-category {
    font-weight: 700;
  }
}
</style>
